@import "~bootstrap/scss/bootstrap";
@import "~bootstrap-icons/font/bootstrap-icons.scss";
@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.5/dist/web/static/pretendard.css");
@import "~tui-date-picker/dist/tui-date-picker.css";
@import "~tui-color-picker/dist/tui-color-picker.css";
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@300&display=swap');

$link-color: $secondary;
$link-decoration: none;
$main-color: #2573d5;

.invalid-feedback {
    display: block;
}

body {
    font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
}

footer {
    .nav > li > a {
        text-decoration:none;
    }
}

#what_space {
    .card {
        .card-body {
            min-height: 112px;
        }
    }
}

#only_members {
    background-color: rgba(#2573d5, .2);
    background-image: url(/images/front-section-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;

    .card {
        border-color: #0b185c;
    }
}

#date-container {
    .tui-datepicker {
        min-width: 306px;
        .tui-datepicker-type-date {
            min-width: 306px;
            .tui-calendar {
                min-width:304px;
            }
        }
    }
}

#date-picker {
    .tui-datepicker {
        min-width: 379px;
        @include media-breakpoint-down(sm) {
            min-width:342px;
        }
        .tui-datepicker-type-date {
            min-width: 379px;
            @include media-breakpoint-down(sm) {
                min-width:342px;
            }
            .tui-calendar {
                min-width:378px;
                @include media-breakpoint-down(sm) {
                    min-width:340px;
                }
                .tui-calendar-date {
                    font-size: 14px;
                }
            }
        }
    }
}

#time-group {
    .time-item {
        margin: 0 10px 10px 0;
    }
}

.navbar-brand {
    font-weight: 800;
    font-size: 32px;
    small {
        font-weight: 400;
        font-size: 14px;
    }
}

#header {
    background-color: rgba($main-color, .2);
    background-size:cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 200px;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    position:relative;
    &::before {
        position:absolute;
        width:100%;
        height: 100%;
        content: '';
        display:block;
        background-color: rgba(8,32,63,0.5);
    }

    @include media-breakpoint-down(sm) {
        height: 80px;
    }

    .title {
        font-size: 2.2rem;
        font-weight: bold;
        color: white;
        position:relative;
        z-index:3000;
        @include media-breakpoint-down(sm) {
            font-size: 1.4rem;
        }
    }
}

#main-nav {
    .nav-item {
        .nav-link {
            font-size: 1.1rem;
            font-weight: bold;
            color: #232c46;
            padding-left: 15px;
            padding-right: 15px;
            &:hover {
                color: #294ab0;
            }
        }
    }
}

#front-quick-link {
    .link-item {
        flex-basis: 30%;
        text-decoration: none;
        figure {
            figcaption {
                font-size: 1.2em;
                color: black;
                margin-top:15px;
            }
        }
    }
}

#icon-index {
    @extend .ms-auto, .me-auto, .flex-wrap;
    max-width: 600px;

    .index-item {
        @extend .d-flex, .flex-column, .align-items-center;
        flex-basis: 25%;
        @include media-breakpoint-down(sm) {
            flex-basis: 30%;
            margin-bottom: 14px;
        }
        .icon {
            @extend .mb-2, .rounded-circle, .d-flex, .justify-content-center, .align-items-center;
            width:48px;
            height:48px;
            background-color: #d1dceb;
            .bi {
                @extend .fs-4;
            }
        }
    }
}



.space-link {
    text-decoration: none;
    color: #232c46;
    &:hover {
        color: #232c46;
    }
}
.space-item {
    transition: all 200ms ease;
    &:hover {
        @extend .shadow;
    }
    .card-body {
        .card-title {
            @extend .text-center, .fw-bold;
        }
        .nav {
            .nav-item {
                flex-basis:calc(50% - 10px);
                margin-right: 10px;
                margin-bottom: 10px;

                &:last-child {
                    flex-basis: 100%;
                }

                .icon {
                    @extend .rounded-circle, .d-flex, .justify-content-center, .align-items-center;
                    background-color: #d1dceb;
                    flex:none;
                    width:32px;
                    height:32px;
                    margin-right: 5px;
                    .bi {
                        @extend .text-dark;
                    }
                }
                span {
                    word-break: keep-all;
                    font-size: .9rem;
                }
            }
        }
    }
}

.space-photos {
    display: block;

    .photo-item {
        float: left;
        width: calc(25% - 10px);
        margin-bottom: 10px;
        margin-right: 10px;
        overflow: hidden;

        &:first-child {
            width: 49.5%;
            margin-bottom: 0;
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
        }
        &:nth-child(3), &:nth-child(5), &:nth-child(7) {
            margin-right: 0;
        }

        &:nth-child(3) {
            border-top-right-radius: 10px;
        }
        &:nth-child(5) {
            border-bottom-right-radius: 10px;
        }

    }
}

#room-intro {
    .icons {
        a {
            display: inline-block;
            margin-right: 10px;
            color: #2c3034;
            &:hover {
                color: #0b5ed7;
            }

            .bi {
                font-size: 24px;
                &.bi-heart-fill {
                    color: #e33221;
                }
            }

        }
    }
}

.space-show {
    font-size: 20px;
    h3 {
        padding-left: 12px;
        position:relative;
        margin-bottom:12px;
        &::before {
            position:absolute;
            top:0;
            left:0;
            bottom: 0;
            content: '';
            width: 4px;
            background-color: $main-color;
        }
    }
    ol {
        li {
            margin-bottom: 10px;
        }
    }
    .info {
        display: flex;
        flex-wrap: wrap;
        padding-left: 20px;
        .info-item {
            @extend .rounded, .d-flex, .align-items-center, .position-relative;
            flex-basis: 43%;
            padding: 20px 20px 20px 40px;
            border: 1px solid #d1dceb;
            margin-right:40px;
            margin-bottom: 20px;

            @include media-breakpoint-down(sm) {
                flex-basis: 100%;
                margin-right: 0;
            }

            .icon {
                @extend .rounded-circle, .d-flex, .justify-content-center, .align-items-center, .position-absolute;
                background-color: #d1dceb;
                flex:none;
                width:48px;
                height:48px;
                left: -24px;
                .bi {
                    @extend .text-dark, .fs-4;
                }
            }
            .excerpt {
                .excerpt-title {
                    font-size: .9em;
                }
                .excerpt-content {
                    @extend .fw-bold;
                }
            }
        }
    }

    .btn-back {
        @include media-breakpoint-down(sm) {
            position: fixed;
            left: 20px;
            bottom: 20px;
            z-index: 3000;
            .bi {
                font-size: 48px !important;
            }
        }
    }
}

#reservation-form {
    #date-picker .tui-datepicker .tui-datepicker-type-date .tui-calendar .tui-calendar-date {
        font-size: 18px;
    }
}

.time-item {
    label.btn {
        font-family: 'Roboto Mono', monospace !important;
    }
}

#welcome-intro {
    font-size: 18px;
    word-break: keep-all;
    @include media-breakpoint-down(sm) {
        font-size: 14px;
    }
}

.tagline {
    font-size: .9em;
}

#footer-container {
    background-color: #2c3034;
    color: #8fa2b4;

    a {
        color: #8fa2b4;
        &:hover {
            color: #6fa3d3;
        }
    }

    .footer-logo {
        font-size: 28px;
        font-weight:700;
        small {
            font-size: 12px;
            display: inline-block;
            margin-bottom: 5px;
            font-weight: 400;
        }
    }

    .footer-bottom {
        font-size: .9em;
    }
}

#admin {

    aside {
        flex-basis: 200px;
        order: 1;
        margin-right:30px;
        background-color: #071f41;
        margin-bottom: 30px;
        #sidemenu {
            .nav-link {
                color: white;
                border-bottom:1px solid #545b72;
                &.active {
                    font-weight:bold;
                    background-color: rgba(61, 112, 206, 0.71);
                    color: white;
                }
                &:hover {
                    background-color: rgba(106, 142, 208, 0.71);
                }
            }
        }
    }
    main {
        flex-basis: calc(100% - 230px);
        order: 2;
        margin-bottom:30px;
    }

    footer {
        margin-top:40px;
    }
}
